<div class="page">
  <div class="page__container">
    <h1 class="page__title">
      {{ 'App.Password.Title' | translate }}
    </h1>

    <form [formGroup]="passwordForm" (ngSubmit)="submitForm()">
      <mat-form-field>
        <input
          type="password"
          formControlName="password"
          maxLength="200"
          matInput
          [placeholder]="'App.Password.InputPlaceholder' | translate"
          (change)="hideErrors()"
        />

        <mat-error *ngIf="isValid('password')">
          {{ 'App.Password.Errors.Invalid' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="btn-wrapper">
        <button class="btn btn--scarlet">
          {{ 'Common.Buttons.Submit.Title' | translate }}
        </button>
      </div>

      <p *ngIf="isPasswordWrong" class="error-message">
        {{ 'App.Password.Errors.Wrong' | translate }}
      </p>

      <p *ngIf="isCommunicationError" class="error-message">
        {{ 'App.Password.Errors.Communication' | translate }}
      </p>
    </form>
  </div>
</div>
