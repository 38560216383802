<div class="page">
  <div class="page__container">
    <h1 class="page__title">
      {{ 'App.Error.Title' | translate }}
    </h1>

    <p class="page__paragraph">
      {{ 'App.Error.Paragraph1' | translate }}
    </p>

    <p class="page__paragraph" [innerHTML]="'App.Error.Paragraph2' | translate"></p>
  </div>
</div>
