<div class="wrapper">
  <div class="container">
    <h1 translate>Toolkit.SessionExpired.Text.H1</h1>
    <p translate>Toolkit.SessionExpired.Text.Pt1</p>
    <p translate>Toolkit.SessionExpired.Text.Pt2</p>
    <a class="btn btn--scarlet" [routerLink]="['/', Route.Login]">
      {{ 'Toolkit.SessionExpired.LogInButton' | translate }}
    </a>
  </div>
</div>
