<main class="content">
  <div class="container">
    <h1>{{ 'App.ForgotPassword.Title' | translate }}</h1>
    <p>{{ 'App.ForgotPassword.Subtitle' | translate }}</p>
    <form [formGroup]="form" class="form">
      <div class="form-control">
        <label for="email">{{ 'App.ForgotPassword.EmailLabel' | translate }}</label>
        <input
          id="email"
          type="text"
          formControlName="email"
          [placeholder]="'App.ForgotPassword.EmailPlaceholder' | translate"
        />
        <mat-error *ngIf="form.get('email')?.touched && form.get('email')?.hasError('required')">
          {{ 'App.ForgotPassword.EmailLabel' | translate }} {{ 'App.ForgotPassword.Errors.Required' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('email')?.touched && form.get('email')?.hasError('pattern')">
          {{ 'App.ForgotPassword.Errors.NotValid' | translate }} {{ 'App.ForgotPassword.EmailLabel' | translate }}
        </mat-error>
      </div>
      <p [innerHTML]="'App.ForgotPassword.Paragraph' | translate"></p>
      <div class="form-control-error" *ngIf="error$ | async">
        {{ error$ | async }}
      </div>
      <button type="submit" class="btn btn--scarlet" (click)="submit()">
        {{ 'App.ForgotPassword.Buttons.Submit' | translate }}
      </button>
    </form>
  </div>
</main>
