<div class="wrapper" *ngIf="{ activities: activities$ | async } as State">
  <ng-container *ngIf="State.activities">
    <div
      class="step-container"
      *ngFor="let activity of State.activities; index as i"
      [ngClass]="{
        'step-container_active': activity.status === ActivityStatusCodes.COMPLETE,
        'step-container_active_white': activity.status === ActivityStatusCodes.IN_PROGRESS
      }"
    >
      <div class="circular">
        <p class="number">{{ i + 1 }}</p>
      </div>
      <p class="step-paragraph" [innerHtml]="activity.activityNameI18nKey | translate"></p>
    </div>
  </ng-container>
</div>
